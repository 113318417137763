.headingName{
    font-size: 22px;
}
@media (max-width: 576px) {
    .headingName {
      font-size: 16px;
    }
    li{
        font-size: 14px;
    }
    p{
        font-size: 14px;
    }
}
li{
    font-size: "16px";
}