/* .home-page header {
    background-image: linear-gradient(rgba(5, 5, 10, 0.75), rgba(5, 5,10, 0.75)), url(../../utils/images/gallery12.jpeg);
}

header {
    background-size: cover;
    background-position: center;
}

.blog-section {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(../../utils/images/blog-section-img.jpg);
    background-size: cover;
    background-position: center;
} */
/* MyCarousel.css */

/* Style the carousel container */
.my-carousel {
    width: 80vw; /* Set the width to 90% of the viewport width */
    margin: auto; /* Center the carousel horizontally */
  }
  
  /* Style the carousel slides to be 30% of the viewport height */
 
  
  /* Center the images within the slides */
  .my-carousel .carousel .slide img {
    max-width: 100%; /* Ensure the image fits within the slide */
    max-height: 100%; /* Ensure the image fits within the slide */
    margin: auto; /* Center the image horizontally */
  }
  
  @media (min-width: 768px) {
    .my-carousel {
      width: 80vw;
      height: 60vh;
    }
    .my-carousel .carousel .slide {
      height: 60vh;
    }
  
    .my-carousel img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  